<script src="./index.js"></script>
<style src="./style.scss" lang="scss" scoped/>
<template>
  <div v-if="isLoading" class="inquiry-wrapper mt-5">
      <PagePreloader layout="list"/>
  </div>
  <div v-else>
    <div class="inquiry-wrapper " v-bind:class="{'open-filter-box': filterBoxActive}">
      <div class="add-property-title d-flex align-items-baseline ">
        <div class=""> {{ $t('vendor-products-title') }}</div>
        <div class="import-button ms-auto " @click="$store.dispatch('utils/openModal', 'productImport')">
          <Button :text="$t('automatic-import')" :theme="'outline-grey'" :size="'small'"/>
        </div>
      </div>
      <div class="row labels-order d-none d-md-flex g-0" v-if="products.length > 0">
        <div class="order-id col-1">{{ $t('vendor-product-id') }}</div>
        <div class="client-name col-7">{{ $t('vendor-products') }}</div>
        <div class="client-phone col-2">{{ $t('vendor-price') }}</div>
        <div class="order-amount col-1"> {{ $t('vendor-visible') }}</div>
        <div class="ms-auto col-1"> {{ $t('actions') }}</div>
      </div>
      <div class="inquiry-item" v-for="product in products">
        <AdminVendorListProduct
            :id="product.id"
            :sku="product.sku"
            :type="'product-list'"
            :date="product.date"
            :category="product.category"
            :title="product.title"
            :visible="product.status === 1"
            :price="product.price"
            :image="product.mainImage !== null ? product.mainImage.src : ''"
            :is-vip="product.isVip"
            @itemDeleted="handleItemDeleted"
        />
      </div>
      <div class="pagination" v-if="products.length > 0">
        <Pagination
            :size="'small'"
            :pages="Math.ceil(totalProducts/limit)"
            :route="'AdminListProducts'"
            :current="currentPage"
            @pageChanged="handlePageChange"
        />
      </div>
      <div class="alert alert-warning mt-3" role="alert" v-else>
        {{ $t('no-products-found') }}
      </div>
    </div>
  </div>


  <Modal id="automated-import"
         :name="'productImport'"
         :size="'large'"
         :is-open="$store.state.utils.modals.productImport"
         :title="$t('automated-import-of-products')">
    <div style="width: 420px; max-width: 100%">
      <div class="mb-4">
        <div>{{ $t('automated-import-of-products-step-1') }}</div>
        <div>{{ $t('automated-import-of-products-step-2') }}</div>
        <div>{{ $t('automated-import-of-products-step-3') }}</div>
        <div>{{ $t('automated-import-of-products-step-4') }}</div>
        <div>{{ $t('automated-import-of-products-step-5') }}</div>
      </div>
      <FileInput @change="e => uploadFile(e)" :file-type="'text/tsv'"/>
      <div class="mt-2">
        <div class="d-flex">{{ $t('field-separator') }}:
          <div class="ms-auto separator-value">tab</div>
        </div>
        <div class="d-flex mt-1">{{ $t('list-field-separator') }}:
          <div class="ms-auto separator-value">;</div>
        </div>
        <div class="d-flex mt-3">{{ $t('example-file') }}:
          <div class="ms-auto"><a href="/static/docs/sakvartirantite.com-product-import-example.xlsx"
                                  target="_blank">{{ $t('download-file') }}</a></div>
        </div>
      </div>
    </div>
  </Modal>
</template>

<style>
#automated-import {
  .modal-body {
    padding-top: 10px!important;
  }
}
</style>